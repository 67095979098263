import { useEffect } from "react";
import Router from "next/router";

import i18nConfig from "../i18n.json";
import { useSelector } from "react-redux";
import { SystemState } from "@store/index";

const { defaultLanguage } = i18nConfig;

export default function Index() {
  // Alternative implementation without Redux
  // const [preference, setPreference] = useState<undefined | any>();
  // const fetchData = async () => {
  //   const { data } = await fetch("/api/geo").then((x) => x.json());
  //   if (JSON.stringify(data) !== JSON.stringify(preference)) {
  //     setPreference(data);
  //   }
  // };
  const language = useSelector<SystemState>((state) => state.language);
  const bootstrap = useSelector<SystemState>((state) => state.bootstrap);

  useEffect(() => {
    // fetchData();

    if (bootstrap) {
      Router.replace(`/${language || defaultLanguage}`);
    }
  }, [bootstrap]);

  return null;
}
